import React from 'react';
import FooterAlt from '../../components/FooterAlt/FooterAlt'

function Privacy() {
    return (
        <div className='Info'>
            <h2>gotpcgame.com data privacy statement</h2>
            <p>This data privacy statement was set up in order to inform concerned persons how their “personal data" (now termed PII related to "Personally identifiable information") is used on the internet. PII, according to US data protection laws and information security, identifies information that solely or in connection to other information, can be used to identify, localise and contact individuals contextually. Please read our data privacy statement carefully, so that you understand how we collect, use, protect or otherwise use your personal data.</p>

            <h4>Which personal data do we collect from the visitors of our blogs, website or users of our app?</h4>
            <p>When you order, particularly once your register, you will be invited to indicate your name, email address, postal code, telephone number or any other details; which will help us to improve your experience as a user.</p>

            <h4>When do we collect data?</h4>
            <p>We collect your data when you place an order or enter information on our website.</p>

            <h4>How do we use your data?</h4>
            <p>Once you register, make a purchase, subscribe to our newsletter, participate in a survey or a marketing communication, visit the website or use any other functions the website has to offer, we will use the data that we collect as follows:</p>
            <ul>
                <li>In order to develop the user’s expe-rience, and to permit us to deliver such contents and product offers for which you have the most interest for.</li>
                <li>To allow us to react better to custo-mer service requests.</li>
                <li>To process the transactions with you at a fast pace.</li>
                <li>In order to send you emails related to your order or other products and services.</li>
            </ul>

            <h4>How do we protect visitor data?</h4>
            <p>Our website is regularly verified for known weaknesses and security loopholes so that your visit of our website is as secure as possible.</p>
            <p>We regularly lead malware scans.</p>
            <p>Your personal data can be found behind secured networks and is only accessible by a limited number of persons, who possess special access rights for such systems and have a duty to treat such information with confidentiality. Moreover, all confidential information that you transmit to us is secured by a Secure Socket Layer (SSL).</p>
            <p>We use a multitude of measures for risk prevention when a user places an order so as to protect your personal data.</p>
            <p>All transactions are processed by a gateway provider and are not saved or processed on our own servers.</p>

            <h4>Do we use "cookies"?</h4>
            <p>Yes. Cookies are small files that a website or a provider saves to the hard disk of your computer via the internet browser (as long as you permit it) and that allow the systems of the website or your provider to recognize your browser and to collect and record certain information. For instance, we use cookies to memorize and process the products in your shopping basket. They will also be used to establish your preferences based on your previous and current website activity sot that we can offer you a better service. In addition, we use cookies to collect general information about the data traffic and the interactions with the website in order to provide an improved user experience as well as better tools.</p>

            <h4>We use cookies to:</h4>
            <ul>
                <li>To memorize the products in the shopping cart and to process them.</li>
            </ul>
            <p>You are free to let your computer warn you when a cookie is being sent or you can deactivate all the cookies. You can do this in the settings of your browser (for example: Internet Explorer). Every browser is a bit different so we suggest you use the help menu of your browsers in order to find the correct settings.</p>
            <p>In case you deactivate cookies, some functions may be deactivated. This has an effect on the user friendliness which our website makes more efficient. Furthermore, some our services will not function properly.</p>
            <p>Yet, you can still abandon your orders.</p>

            <h4>Disclosures by third parties</h4>
            <p>We will not sell, trade or transmit to any third parties by any other means your personal data.</p>

            <h4>Links by third party suppliers</h4>
            <p>Occasionally, we can, at our discretion, offer products or services from third party suppliers on our website. Such third party websites have their own, independent data protection guidelines. Therefore, we do not carry any responsibility or liability for the contents and activities of the linked websites. Nevertheless, we still want to protect the integrity of our website and welcome any feedback in relation to these websites.</p>

            <h4>Google</h4>
            <p>The requirements for Google adverts can be summarised by the general principles of Google advertising. Their purpose is to enable a positive experience for the user. <a href='https://support.google.com/adwordspolicy/answer/1316548?hl=de' style={{overflowWrap: 'break-word' }}>https://support.google.com/adwordspolicy/answer/1316548?hl=de</a></p>
            <p>On our website, Google AdSense is currently not activated but we can activate it at any given time in the future.</p>

            <h4>California Online Privacy Protection Act</h4>
            <p>CalOPPA is the Staet law in the US, which obligates commercial websites and online services to publically disclose data protection guidelines. This law extends beyond California and requires that a website owner in the United States (potentially also worldwide) whose website raises personal data from Californian consumers, needs to publish a visible privacy statement on his website on which it is clearly described which data is collected and who has access to it. To find out more about this, visit: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</p>

            <h4>According to CalOPPA, we agree to the following:</h4>
            <p>
                Users can visit our website anonymously.<br/>
                As soon as these data privacy guidelines are drawn up, we will publish them on our starting page and link you to our first significant page once one enters our website.<br/>
                The link to our data protection guidelines comprises the term “Data protection" and can be easily found on the webpage indicated at the top.
            </p>
            <p>Users will be informed of any amendments to our data protection guidelines:</p>
            <ul>
                <li>Via email</li>
            </ul>
            <p>Users can modify their personal information:</p>
            <ul>
                <li>Via email</li>
                <li>Via Telephone</li>
            </ul>

            <h4>How does our website deal with Do-Not-Track-Signals?</h4>
            <p>We respect Do-Not-Track-Signals and do not follow, place or advertise when a Do-Not-Track (DNT) browser mechanism is available.</p>

            <h4>Does our website allow third party suppliers to pursue behavioural issues?</h4>
            <p>We also want to inform you that we do not allow third party suppliers to pursue behavioural issues.</p>

            <h4>COPPA (Children Online Privacy Protection Act)</h4>
            <p>The provisions of the Children's Online Privacy Protection Act (COPPA) give parents control over the collection of personal data of children under the age of 13. The Federal Trade Commission, the national consumer protection facility applies and carries out the COPPA regulation, which provides for what website owners and online services have to do in order to protect children’s privacy and security on the internet.</p>
            <p>We do not exclusively market to children under the age of 13.</p>

            <h4>Fair information practices</h4>
            <p>The Fair Information Practices Principles are the foundation of the data protection act in the United States and the principles contained therein play an important role in the worldwide development of data protection laws. An understanding of the Fair Information Practice principles and how they should be applied in practice is important for the adherence of different data protection laws and the protection of personal data.</p>

            <h4>To follow Fair Information Practices and in case a violation of data protection laws occurs we will do the following:</h4>
            <p>We will inform the user via email:</p>
            <ul>
                <li>Within 1 working day</li>
            </ul>
            <p>Besides, we agree with the principles of individual remedy, where persons have a right to exercise and enforce their rights in accordance to the law against data collectors and processors who do not abide to the law. This principle does not only require an individual to have enforceable rights against the data user but also that this person has access to the courts and state authorities so as to investigate and/or to criminally prosecute the data processor for not respecting the law.</p>

            <h4>CAN SPAM Act</h4>
            <p>The CAN-SPAM Act is a law that regulates commercial emails, imposes requirements on advertising messages, gives receivers the right to put a stop to receiving emails and formulates strict sanctions in case of violations.</p>

            <h4>We gather your email address in order to:</h4>
            <ul>
                <li>Send information, answer requests and/or any other wishes or ques-tions.</li>
                <li>To process orders, to send infor-mation and updates that are related to those orders.</li>
                <li>We can also send you additional in-formation about your product and services.</li>
                <li>To market our mailing list or to con-tinue sending emails to our clients after the initial transaction has taken place.</li>
            </ul>

            <h4>In order to comply with the CANSPAM requirements, we agree with the following:</h4>
            <ul>
                <li>We do not use false or deceptive subject lines or email addresses.</li>
                <li>The message will be appropriately labelled as an advertisement.</li>
                <li>The address or location of our busi-ness headquarters will be mentioned in the email.</li>
                <li>If applicable, the email marketing services of third party suppliers will be verified for compliance.</li>
                <li>Promptly carry out opt-out/unsubscribe requests.</li>
                <li>To allow that users can unsubscribe from their subscription by clicking on a link that can be found at the end of every email.</li>
            </ul>

            <h4>In case you want to unsubscribe from receiving future emails at any time, just send us an email at</h4>
            <ul>
                <li>Follow the instructions at the end of each email.</li>
            </ul>
            <p>And we will instantly remove you from any correspondence.</p>

            <h4>Enter in contact with us</h4>
            <p>In case you have questions about the privacy statement, please contact us via email:</p>
            <p>
                Novads<br/>
                Registration number: 1563366 and VAT: 1563366<br/>
                support @ gotpcgame.com
            </p>

            <p>Copyright © gotpcgame.com {new Date().getFullYear()}</p>

            <FooterAlt />
        </div>
    )
}


export default Privacy