import './FooterAlt.scss'
import { NavLink } from 'react-router-dom'

const FooterAlt = () => {
  return (
    <div className='FooterAlt'>
        <NavLink className='FooterAlt__link' to='/privacy'>Privacy Policy</NavLink>
        <span className='FooterAlt__delimiter'> | </span>
        <NavLink className='FooterAlt__link' to='/terms'>Terms of Service</NavLink>
        <span className='FooterAlt__delimiter'> | </span>
        <NavLink className='FooterAlt__link' to='/contacts' >Contacts</NavLink>
    </div>
  )
}

export default FooterAlt