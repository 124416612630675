import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Terms from './pages/Terms/Terms';
import Contacts from './pages/Contacts/Contacts';
import Privacy from './pages/Privacy/Privacy';
import Landing from './pages/Landing/Landing';

function App() {
  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route path='/terms' component={Terms} />
          <Route path='/contacts' component={Contacts} />
          <Route path='/privacy' component={Privacy} />
          <Route exact path='/' component={Landing} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
