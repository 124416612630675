import React from 'react';
import FooterAlt from '../../components/FooterAlt/FooterAlt'

function Terms() {
    return (
        <div className='Info'>
            <p>The following Terms of Use ("Terms") govern your use of gotpcgame.com website, which includes news content, blogs, services and advertising or "Sponsored Content" (Collectively "Website"). These Terms set forth important details about your relationship with us including restrictions on how you can use our Website and our liability in the event something goes wrong. These Terms of Use also sets out our agreement as to how we will resolve disputes through the use of a binding arbitration and you waive the right to participate in class action litigation.</p>
            <p>BY ACCESSING OR USING OUR WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS. IF NOT, PLEASE DO NOT USE OR ACCESS OUR WEBSITE.</p>
            <p>We reserve the right to modify the Terms at any time without prior notice to you. We ask that you read these Terms carefully each time you use our Website. All changes to these Terms will be effective from the date posted forward. By using our Website after any change, you accept all new or revised provisions in any revised posted Terms.</p>

            <h2>1. Privacy Policy Incorporated.</h2>
            <p>We have prepared and posted a Privacy Policy on our Website which governs and informs you of our data collection and use practices. We are incorporating this Policy into these Terms and we are both bound by this policy, which we encourage you to review.</p>

            <h2>2. Changes to Our Website.</h2>
            <p>You agree and understand that our Website including, all content and sponsored content , may be modified or discontinued at any time, in our sole discretion, without prior notice. All changes are subject to these Terms.</p>

            <h2>3. Ownership of Intellectual Property.</h2>
            <p>All text, graphics interfaces, photographs, trademarks, logos, sounds, music, artwork, computer code, and content (collectively "Content") published on the Website is protected by applicable intellectual property laws and is owned or licensed by gotpcgame.com or its licensors. You may not modify, create a derivative work, display, distribute, or exploit, in whole or in part, any of the Content or software contained on, or comprising, our Website without seeking prior written permission from us. You are restricted from using any automated or manual device or process to copy, monitor, index or data mine the Website. gotpcgame.com, its respective logos, trade dress, and the graphics and layout of the Website are the registered and/or unregistered service marks, trademarks, and/or trade dress of gotpcgame.com and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, product names, and company names or logos mentioned on the Website are the property of their respective owners. Nothing in these Terms shall be deemed to grant to you any license or right in or to any other proprietary right of ours pertaining to the products sold on and through our Website.</p>

            <h2>4. License to Use and Copy.</h2>
            <p>We grant you a limited license to make personal use of content on our Website. This license does not include: (a) any resale or commercial use of content on our Website; (b) the collection and use of any product listing or description on our Website; or (c) use of any data mining, robots, or similar data gathering and extraction methods on our Website.</p>

            <h2>5. Restrictions on Your Use of our Website.</h2>
            <p>Without limiting the generality of any other provisions of these Terms, you agree you shall not: (i) download, modify, reproduce, adapt, translate, reverse engineer, create derivative works based upon, publicly display, sell, rent, license, or in any way commercially exploit any portion of our Website or Content; (ii) remove any copyright, trademark or other proprietary rights notice contained in or on our Website; (iii) use any robot, spider, search application, or other device to retrieve or index any portion of our Website; (iv) transmit or upload to our Website any software or code containing any virus, worm, defect, Trojan horse, software bomb or other feature designed to damage or degrade the performance of our Website or any computer utilized to access our Website; (v) use our Website to intentionally or unintentionally, violate any applicable local, state, federal or international law; or (vi) collect or store personal or non-personal data about others in connection with our Website.</p>

            <h2>6. DMCA Notice.</h2>
            <p>If you believe that any item or content on our Website infringes your copyright, you should send written notice of copyright infringement to our designated copyright agent at the address given below. Your notice must meet the requirements of the Digital Millennium Copyright Act (as required under 17 U.S.C. §512) by providing the following information:</p>
            <p>
                6.1. A description of the copyrighted work that you claim has been infringed;<br />
                6.2. A description of where the alleged copyrighted work is located on our Website;<br />
                6.3. Your name, address, telephone number and email address;<br />
                6.4. A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent or the law;<br />
                6.5. A statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the owner of the copyright at issue or are authorized to act on the copyright owner's behalf; and<br />
                6.6. An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright at issue.
            </p>
            <p>Our designated copyright agent may be reached by emailing support@techmsx.com.</p>

            <h2>7. Disclaimer.</h2>
            <p>YOU EXPRESSLY AGREE THAT YOUR USE OF OUR WEBSITE, AND THE CONTENT, INFORMATION, ADVICE, SERVICES, PRODUCTS, AND OTHER MATERIALS CONTAINED ON OR DESCRIBED ON OUR WEBSITE IS ENTIRELY AT YOUR OWN RISK, AND THAT ALL SUCH INFORMATION AND MATERIALS ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, WE MAKE NO, AND HEREBY DISCLAIM ALL, REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE AVAILABILITY, OPERATION AND/OR USE OF OUR WEBSITE, INCLUDING BUT NOT LIMITED TO ALL CONTENT, SERVICES, PRODUCTS, AND OTHER INFORMATION AND MATERIALS CONTAINED IN OR DESCRIBED ON OUR WEBSITE. WE FURTHER DISCLAIM ANY WARRANTY EXPRESS OR IMPLIED OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE. IN ADDITION, WE DO NOT REPRESENT OR WARRANT THAT THE INFORMATION AND CONTENT ACCESSIBLE THROUGH OUT WEBSITE IS ACCURATE, COMPLETE OR CURRENT, AND WE ARE NOT RESPONSIBLE FOR ANY ERRORS OR OMISSIONS THEREIN OR FOR ANY ADVERSE CONSEQUENCES RESULTING FROM THE USE OR INABILITY TO USE, OR RELIANCE ON ANY ASPECT OF OUR WEBSITE ITS CONTENT, SERVICES, PRODUCTS, AND OTHER INFORMATION AND MATERIALS CONTAINED IN OR DESCRIBED ON OUR WEBSITE. FURTHER, WE MAKE NO REPRESENTATIONS AND WARRANTIES THAT OUR WEBSITE WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR OUR CUSTOMER SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.</p>

            <h2>8. Limitation on Liability.</h2>
            <p>IN NO EVENT SHALL WE, OUR AFFILIATES, SHAREHOLDERS, DIRECTORS, OFFICERS, MEMBERS, MANAGERS, EMPLOYEES, AGENTS OR REPRESENTATIVES OF ANY OF THE FOREGOING, BE LIABLE TO YOU OR ANY THIRD PARTY FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES OR DAMAGES FOR LOSS OF PROFITS, GOODWILL OR REVENUE, BUSINESS INTERRUPTION, OR LOSS OF DATA) ARISING OUT OF THE USE OF, ACCESS TO, RELIANCE ON, UNAVAILABILITY OF, INABILITY TO USE, OR IMPROPER USE OF OUR WEBSITE, INCLUDING BUT NOT LIMITED TO ANY USER CONTENT, REVIEWS, ADVICE, SERVICES, PRODUCTS, AND OTHER INFORMATION AND MATERIALS CONTAINED IN OR DESCRIBED ON OUR WEBSITE. THIS LIMITATION INCLUDES ANY DAMAGES OF ANY NATURE FOR THE DISCLOSURE OR MISUSE OF ANY YOUR PERSONAL INFORMATION, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF THE FORM OF LEGAL ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE. IN ALL EVENTS, OUR TOTAL MAXIMUM LIABILITY SHALL BE LIMITED TO ONE HUNDRED U.S. DOLLARS ($100.00). THIS LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY PROVIDED HEREIN. THIS IS A BARGAINED AND AGREED TO PROVISION</p>
         
            <h2>9. Indemnification.</h2>
            <p>You agree to indemnify and hold us harmless including our affiliates, licensors, directors, officers, members, managers, employees, agents, and representatives, from and against any losses, costs, expenses or damages of any nature whatsoever (including attorneys' fees and court costs) arising from any claim, cause of action, suit or demand of any third party due to, arising out of or related to (i) your access to or use of our Website , or (ii) your violation of these Terms of Use.</p>

            <h2>10. Disclaimer of Advertisements and Links to Third Party Website.</h2>
            <p>We may from time to time display advertisements from third parties on our Website. These advertisements may be in the form of a sponsored content article, banner, link, pop-under or pop-up ad. We are not responsible for the content of such advertisements or links, or for any products, services or other materials relating to such advertisements or any click through or linked website. We are paid only for the display of the advertisement and the display or link does not represent or imply that we endorse such products, services or Website. IN NO EVENT WILL WE BE LIABLE, DIRECTLY OR INDIRECTLY, TO ANYONE FOR ANY DAMAGE, INCLUDING COMPENSATORY OR PUNITIVE OR ANY LOSS ARISING FROM OR RELATING TO SUCH ADS, LINKS OR WEBSITE.</p>

            <h2>11. Confidential Binding Arbitration.</h2>
            <p>You agree that by entering into these Terms, you and we are each waiving the right to trial by jury and the ability to participate in a class action.</p>
            <p>11.1. ALL DISPUTES BETWEEN YOU AND US RELATING IN ANY WAY TO OUR WEBSITE AND THE PRODUCTS ADVERTISED THEREON (INCLUDING WITHOUT LIMITATION ORDERS MADE FROM ADVERTISING ON OUR WEBSITE, RELATED ADVERTISEMENTS AND DISCLOSURES, EMAIL MESSAGES WE SEND TO YOU, OR RELATING TO THE COLLECTION OR USE OF ANY INFORMATION ABOUT YOU IN CONNECTION WITH OUR WEBSITE), THESE TERMS, OR YOUR STATUS AS A USER WILL BE SUBMITTED TO CONFIDENTIAL ARBITRATION IN THE FEDERAL JUDICIAL DISTRICT IN WHICH YOU RESIDE, EXCEPT TO THE EXTENT THAT YOU HAVE, IN ANY WAY, VIOLATED OR THREATENED TO VIOLATE OUR INTELLECTUAL PROPERTY RIGHTS.</p>
            <p>11.2. If you intend to seek arbitration, you must first send to us, by certified mail, a written Notice of Dispute ("Notice"). The Notice must be mailed to support@techmsx.com. It must be addressed to the attention of Legal Counsel. The Notice shall describe the nature and basis of the claim or disputes and the specific relief sought. If we cannot reach an agreement to resolve the claim within thirty (30) days after the Notice is received, either party may commence arbitration.</p>
            <p>11.3. All arbitrations required by these Terms will be conducted under the rules of the American Arbitration Association. The arbitrator's award is binding and may be entered in any court of competent jurisdiction. To the fullest extent permitted by applicable law, no arbitration brought under these Terms may be joined to an arbitration involving any other party subject to these Terms whether through class arbitration proceedings or otherwise. Unless we and you agree otherwise, the arbitrator may not consolidate your claim with any other claim and may not otherwise preside over any form of a representative, private attorney general or class proceeding.</p>
            <p>11.4. For arbitration claims you assert against us in accordance with this Section 13, we will pay all of your administrative, hearing, and arbitrator's fees and costs for the arbitration but not the fees, expenses, and costs of your lawyers, experts, or witnesses in excess of any filing fee you would have been required to pay to file the claim as a lawsuit in a state or federal court (whichever is greater) in the judicial district in which you reside. The arbitrator may award money or equitable relief in favor of only the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim. Similarly, an arbitration award and any judgment confirming it can apply only to that specific case; it cannot be used in any other case except to enforce the award itself. To reduce the time and expense of the arbitration, the arbitrator will not provide a statement of reasons for his or her award unless requested to do so by all parties.</p>
            <p>11.5. TO THE EXTENT PERMITTED BY LAW, YOU WILL NOT AGREE TO ACT AS A CLASS REPRESENTATIVE OR A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT AGAINST US IN ANY COURT, OR IN ARBITRATION, WITH RESPECT TO ANY CLAIMS RELATING IN ANY WAY TO US OR OUR WEBSITE INCLUDING WITHOUT LIMITATION ORDERS MADE FROM OUR WEBSITE, OUR ADVERTISEMENTS AND DISCLOSURES, EMAIL OR RELATING TO THE COLLECTION OR USE OF ANY INFORMATION ABOUT YOU IN CONNECTION WITH OUR WEBSITE, THESE TERMS, OR YOUR STATUS AS A PURCHASER OF OUR PRODUCTS OR SERVICES. THIS SECTION 14 WILL SURVIVE THE TERMINATION OF THE RELATIONSHIP BETWEEN YOU AND US.</p>

            <h2>12. Applicable law.</h2>
            <p>By visiting our Website, you agree that the laws of Estonia, without regard to principles of conflict of laws, will govern these Terms and any dispute of any sort that might arise between you and us.</p>

            <h2>13. General Terms.</h2>
            <p>No agency, partnership, joint venture, or employment relationship is created as a result of these Terms. These terms constitute the entire agreement between us. Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of the enforcement of such right or provision. If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect and enforceable. These Terms are not assignable, transferable or sublicensable by you, except with our prior written consent. The headings in these Terms are for convenience only and have no legal or contractual effect. These Terms include and incorporate by reference the posted Privacy Policy on our Website.</p>
            <p>Copyright © gotpcgame.com {new Date().getFullYear()}</p>

            <FooterAlt />
        </div>
    )
}


export default Terms