import './Loading.scss'
import React, { useState, useEffect } from 'react'

const Loading = ({ changeStep }) => {
  const [loading, setLoading] = useState(96)

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setLoading(loading => loading - 6)
      }, 100);
      return () => clearInterval(timer)
    } else changeStep(1)
  }, [loading, changeStep])
  
  return (
    <div className='Text__item'>
      <div className='Text__item'>
        LOADING GAME
      </div>
      <div className='Text__item Loading'>
        <div className='Loading__item Loading__item--left' style={{width: `${100 - loading}%`}}></div>
        <div className='Loading__item Loading__item--right' style={{width: `${loading}%`}}></div>
      </div>
    </div>
  )
}

export default Loading