import React from 'react';
import FooterAlt from '../../components/FooterAlt/FooterAlt'

function Contacts() {
    return (
        <div className='Info'>
            <h2>Do you need help?</h2>
            <p>In case you want to receive further information about the listed products or you want to suggest a product, we will be happy to get in touch!</p>
            <p>Send an email to: support @ gotpcgame.com</p>
            <p>Copyright © gotpcgame.com {new Date().getFullYear()}</p>

            <FooterAlt />
        </div>
    )
}


export default Contacts