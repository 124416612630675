import './Buttons.scss'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const Buttons = ({ buttons, redirect, changeStep, quizstyle }) => {
  const handleClick = () => {
    if (redirect) window.location.assign(redirect)
    else changeStep(1)
  }

  const renderButtons = () => {
    if (Array.isArray(buttons))
      return buttons.map((item, index) =>
        <TransitionGroup key={item} className='Buttons__item'>
          <CSSTransition
            appear={true}
            key={index}
            exit={false}
            classNames="notifyTransition"
            timeout={{ appear: 300, enter: 300, exit: 0 }}
          >
            <div onClick={handleClick}>
              <img className='Buttons__item__img' src={`/content/quizstyle/${quizstyle}/button/btn.png`} alt={item}></img>
              <span className='Buttons__item__text'>{item}</span>
            </div>
          </CSSTransition>
        </TransitionGroup>
      )
    return null
  }

  return (
    <div className='Buttons'>
      {renderButtons()}
    </div>
  )
}

export default Buttons