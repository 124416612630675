import './Steps.scss'

const Steps = ({ step, stepsCount, title, arrow, quizstyle, changeStep }) => {
  const renderArrow = (position) => {
    const arrowDisabled = (position === 'left' && step === 1) || (position === 'right' && step === stepsCount)
    return arrow
    ? <div className='Steps__arrow' onClick={() => { if (!arrowDisabled) changeStep(position === 'left' ? -1 : 1)  }}>
      <img className={`Steps__arrow__img ${arrowDisabled ? 'disabled' : ''}`} src={`/content/quizstyle/${quizstyle}/arrows/${position}.png`} alt={`${position === 'left' ? 'prevous' : 'next'} step`}></img>
    </div>
    : null
  }

  return (
    <div className='Steps'>
      {renderArrow('left')}
      <div className='Steps__text'>
        {title || `STEP ${step} OF ${stepsCount}`}
      </div>
      {renderArrow('right')}
    </div>
  )
}

export default Steps