import './Frame.scss'
import Buttons from '../../components/Buttons/Buttons'
import Steps from '../../components/Steps/Steps'
import Text from '../../components/Text/Text'

const Frame = ({ stepsCount, step, stepData = {}, title, redirect, quizstyle, changeStep, geo }) => {
  return (
    <div className='Frame'>
      <img className='Frame__box' src={`/content/quizstyle/${quizstyle}/frame/box.png`} alt='box'/>
      <Steps step={step} stepsCount={stepsCount} title={title} arrow={stepData.arrows} quizstyle={quizstyle} changeStep={changeStep} />
      <Text rows={stepData.rows} changeStep={changeStep} geo={geo} />
      <Buttons buttons={stepData.buttons} redirect={redirect} changeStep={changeStep} quizstyle={quizstyle} />
    </div>
  )
}

export default Frame