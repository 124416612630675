import './Footer.scss'

const Footer = () => {
  return (
    <div className='Footer'>
        <a className='Footer__link' href='https://plarium.com/de/legal/terms-of-use/' target='_blanc'>ALLGEMEINE NUTZUNGSBEDINGUNGEN</a>
        <a className='Footer__link' href='https://bestemmospiele.com/impressum.html' target='_blanc'>Impressum-Kontakte</a>
        <a className='Footer__link' href='https://plarium.com/de/legal/privacy-and-cookie-policy/' target='_blanc'>DATENSCHUTZ- UND COOKIE-RICHTLINIEN</a>
    </div>
  )
}

export default Footer